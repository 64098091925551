<template>
  <b-row>
    <b-col
      v-for="(element, index) in elements"
      :key="index"
      cols="12"
      class="mb-2"
    >
      <statistics-card
        :title="element.title"
        :element="element.statistics"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BRow, BCol } from 'bootstrap-vue'
import { fetchQuickSummary } from '@/application/dashboardService'
import StatisticsCard from './components/StatisticsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticsCard,
  },
  setup() {
    const elements = ref([])
    fetchQuickSummary().then(val => {
      elements.value = val
    })
    return {
      elements,
    }
  },
}
</script>
