<template>
  <section>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title>{{ title }}</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in items"
            :key="item.icon"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  :text="item.type"
                  :variant="item.color"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ element[item.name] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    element: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: [{
        name: 'today',
        color: 'light-primary',
        type: 'С',
        subtitle: 'Сегодня',
      }, {
        name: 'yesterday',
        color: 'light-info',
        type: 'В',
        subtitle: 'Вчера',
      }, {
        name: 'currentWeek',
        color: 'light-primary',
        type: 'ТН',
        subtitle: 'тек неделя',
      }, {
        name: 'previousWeek',
        color: 'light-info',
        type: 'ПН',
        subtitle: 'Пред неделя',
      }, {
        name: 'currentMonth',
        color: 'light-danger',
        type: 'ТМ',
        subtitle: 'Тек месяц',
      }, {
        name: 'previousMonth',
        color: 'light-success',
        type: 'ПМ',
        subtitle: 'Пред месяц',
      }],
    }
  },
}
</script>
